.Week > td {
     border: 0.5px solid #ccc; 
}

.Calendar{
     background-color: white;
     padding: 10px;
     width: 100%;
}

.DayOfWeek{
     background-color: #e8e9ec;
     padding: 10px 0;
font-weight: 100;  
  line-height: 1.2;
  text-align: center;
  color: #757575;
  margin-top: 20px;
}

.next {
     justify-content: flex-end
}

.days-header{
     margin-top: 20px;
     border: 1px solid #e8e9ec;
}

.heading:before {
     /* This doesn't work because of border-collapse */
     line-height:1em;
     content:".";
     color:white;
     display:block;
 }

.nav-inner {
     background: white;
     border: 1px solid #ccc;
}


.month , .year {
     width: 78px;
  height: 14px;
  font-size: 12px;
  line-height: 1.17;
  color: #393939;
}

.selected {
     background-color: #4c10c1;
     color: white;
}