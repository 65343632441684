.progress-bar:after {
     content: ""!important;
}


.custom-progress {
     display: flex;
     height: 10px;
     overflow: hidden;
     font-size: .75rem;
     background-color: #e9ecef;
     border-radius: .25rem;
}