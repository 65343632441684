


@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(/fonts/GothamBold.ttf) format('truetype');
}


@font-face {
  font-family: 'GothamBook';
  src: local('Gotham'), url(/fonts/GothamBook.ttf) format('truetype');
}

body,html {
  margin: 0;
  padding: 0;
  width : 100%;
  height : 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 
    "Segoe UI", "Roboto", "Oxygen", 
    "Ubuntu", "Cantarell", "Fira Sans", 
    "Droid Sans", "Helvetica Neue", sans-serif;
}


.MuiIconButton-root-157{
  flex: 0 0 auto;
 color: rgba(0, 0, 0, 0.54);
 padding: 0px!important;
 overflow: visible;
 font-size: 1.5rem;
 text-align: center;
 -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
 transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
 border-radius: 50%;
}


.MuiIconButton-root-196 {
  flex: 0 0 auto;
 color: rgba(0, 0, 0, 0.54);
 padding: 0px!important;
 overflow: visible;
 font-size: 1.5rem;
 text-align: center;
 -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
 transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
 border-radius: 50%;
}



textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
select:active, 
option:active, 
option:focus, 
select:focus,
textarea:focus,
button:focus,
button:active,
button:hover,
label:focus,
.form-control:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
}

.root{
     padding:  0;
}

.MuiIconButton-root-157{
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}


.MuiIconButton-root-196 {
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.root{
     padding: 0px!important;
   }

.imputtext {
   
          font-family: system-ui;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #757575;
        
}
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.root{
     padding:  0;
}

.MuiIconButton-root-157{
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}


.MuiIconButton-root-196 {
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.root{
     padding: 0px!important;
   }
.root{
     padding:  0;
}

.MuiIconButton-root-157{
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}


.MuiIconButton-root-196 {
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.root{
     padding: 0px!important;
   }
.root{
     padding:  0;
}

.MuiIconButton-root-157{
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}


.MuiIconButton-root-196 {
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.root{
     padding: 0px!important;
}

.MuiIconButton-root-63 {
     flex: 0 0 auto;
     color: rgba(0, 0, 0, 0.54);
     padding: 0px!important;
     overflow: visible;
     font-size: 1.5rem;
     text-align: center;
     -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     border-radius: 50%;
}
.progress-bar:after {
     content: ""!important;
}


.custom-progress {
     display: flex;
     height: 10px;
     overflow: hidden;
     font-size: .75rem;
     background-color: #e9ecef;
     border-radius: .25rem;
}
.Day-inner {
     width: 100%;
     background-color: inherit;
     color: inherit;
     border:none;

}
.Week > td {
     border: 0.5px solid #ccc; 
}

.Calendar{
     background-color: white;
     padding: 10px;
     width: 100%;
}

.DayOfWeek{
     background-color: #e8e9ec;
     padding: 10px 0;
font-weight: 100;  
  line-height: 1.2;
  text-align: center;
  color: #757575;
  margin-top: 20px;
}

.next {
     justify-content: flex-end
}

.days-header{
     margin-top: 20px;
     border: 1px solid #e8e9ec;
}

.heading:before {
     /* This doesn't work because of border-collapse */
     line-height:1em;
     content:".";
     color:white;
     display:block;
 }

.nav-inner {
     background: white;
     border: 1px solid #ccc;
}


.month , .year {
     width: 78px;
  height: 14px;
  font-size: 12px;
  line-height: 1.17;
  color: #393939;
}

.selected {
     background-color: #4c10c1;
     color: white;
}
