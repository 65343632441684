.root{
     padding:  0;
}

.MuiIconButton-root-157{
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}


.MuiIconButton-root-196 {
     flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px!important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.root{
     padding: 0px!important;
}

.MuiIconButton-root-63 {
     flex: 0 0 auto;
     color: rgba(0, 0, 0, 0.54);
     padding: 0px!important;
     overflow: visible;
     font-size: 1.5rem;
     text-align: center;
     transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     border-radius: 50%;
}