


@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(/fonts/GothamBold.ttf) format('truetype');
}


@font-face {
  font-family: 'GothamBook';
  src: local('Gotham'), url(/fonts/GothamBook.ttf) format('truetype');
}

body,html {
  margin: 0;
  padding: 0;
  width : 100%;
  height : 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 
    "Segoe UI", "Roboto", "Oxygen", 
    "Ubuntu", "Cantarell", "Fira Sans", 
    "Droid Sans", "Helvetica Neue", sans-serif;
}


.MuiIconButton-root-157{
  flex: 0 0 auto;
 color: rgba(0, 0, 0, 0.54);
 padding: 0px!important;
 overflow: visible;
 font-size: 1.5rem;
 text-align: center;
 transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
 border-radius: 50%;
}


.MuiIconButton-root-196 {
  flex: 0 0 auto;
 color: rgba(0, 0, 0, 0.54);
 padding: 0px!important;
 overflow: visible;
 font-size: 1.5rem;
 text-align: center;
 transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
 border-radius: 50%;
}



textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
select:active, 
option:active, 
option:focus, 
select:focus,
textarea:focus,
button:focus,
button:active,
button:hover,
label:focus,
.form-control:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
}
